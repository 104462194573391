$primary: #5E43FD;
$primaryHover: #5E43FD;

$red: #ff5d51;
$green: #80d022;

$white: #EAEBEB;
$dark1: #A5A6A6;
$dark2: #7A7B7C;
$dark3: #5D5E5E;
$dark4: #424444;
$dark5: #353636;
$dark6: #20201F;
$black: #141414;

:export {
    primary: $primary;
    primaryHover: $primaryHover;

    red: $red;
    green: $green;

    white: $white;
    dark1: $dark1;
    dark2: $dark2;
    dark3: $dark3;
    dark4: $dark4;
    dark5: $dark5;
    dark6: $dark6;
    black: $black;
}