@use './../colors.module';

.moduleBrowser {
    color: colors.$black;
    position: relative;
}

.module {
    position: relative;
    margin-bottom: 16px;
    > div {
        /* module container */
        cursor: pointer;
        @media (pointer: fine) {
            &:hover {
                > div:first-child {
                    border: 3px solid colors.$primary;
                }
            }
        }

        > div:first-child {
            /* image container */
            border: 3px solid transparent;
            background: #333;
            position: relative;
            border-radius: 12px;
            overflow: hidden;
            &:after {
                content: '';
                display: block;
                padding-bottom: 62%;
            }
            img {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
            }
        }
        > div:last-child {
            /* description container */
            padding-top: 16px;
            white-space: initial;
            > span:first-child {
                /* title */
                font-weight: bold;
                font-size: 17px;
                line-height: 22px;
                display: block;
                color: colors.$white;
            }
            > span:last-child {
                /* description */
                color: #95939f;
                font-size: 15px;
                line-height: 23px;
            }
        }
    }
}

.preview {
    position: fixed;
    pointer-events: none;
    transform: translateX(-50%) translateY(10%);
    width: 400px;
    @media (max-width: 767px) {
        width: 250px;
    }
    img {
        width: 400px;
        display: block;
    }
}

.templateImage {
    background-position: center !important;
    background-size: cover !important;
}
